.inputWrapper {
    display: flex;
    position: relative;
    font-size: 26px;
    line-height: 32px;
    font-family: sans-serif;
}
.input {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    width: 100%;
    outline: none;
    border-radius: 10px;
}
.inputFakeValueWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
}
.inputFakeValue {
    overflow: hidden;
}
.suffix {
    visibility: visible;
    height: 100%;
    display: flex;
    align-items: center;
}
