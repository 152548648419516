.shine {
    overflow: hidden;
}

.shine::before {
    content: '';
    position: absolute;
    width: 300px;
    height: 100%;
    background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 10%,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    animation: shine 3s infinite linear; /* Animation */
}

@keyframes shine {
    0% {
        left: -100px;
    }
    20% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}
